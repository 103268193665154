// import React from 'react'
// import { shallow } from 'enzyme'

// import StoryblokComponents from '../index'

describe('<StoryblokComponents />', () => {
  it('Expect to have unit tests specified', () => {
    expect(true).toEqual(false)
  })
})
